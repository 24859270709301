#changelog {
  .card-img-top {
    max-width: 320px !important;
    margin: auto;
  }
  .p-02 {
    padding: 0 2px;
  }
  .p-03 {
    padding: 0 3px;
  }
  .ico-container {
    margin: 0.25rem;
    padding: 0.5rem;
    height: 130px;
    cursor: pointer;
    position: relative;
  }

  .device-select-container {
    .ico-container:not(:last-child):after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 1px;
      background-color: #e5e5e5;
    }
  }

  .device-container {
    color: #ffffff;
    background-color: #af4aff;
    padding: 0.25rem 0.5rem;
    max-height: 32px;
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }

  .change-log-fix {
    background-color: #af4aff;
    max-width: 74px;
    max-height: 32px;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    margin-top: 25px;
  }
  .change-log-new {
    background-color: #20deb0;
    max-width: 74px;
    max-height: 32px;
    height: 100%;
    width: 100%;
    border-radius: 8px;
  }
  .change-log-update-type {
    background-color: #ddda08;
    margin-top: 25px;
    max-height: 32px;
    height: 100%;
    width: fit-content;
    border-radius: 8px;
    padding: 0 15px;
  }
  .automatic-update {
    color: #ffffff;
    background-color: #3092df;
    max-height: 32px;
    height: 100%;
    width: 300px;
    text-align: center;
    border-radius: 8px;
    padding: 0.25rem 0.5rem;
  }

  .manual-update {
    color: #ffffff;
    background-color: #3092df;
    max-height: 32px;
    height: 100%;
    text-align: center;
    width: 300px;
    border-radius: 8px;
    padding: 0.25rem 0.5rem;
  }

  //accordion

  .accordion-button {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
    padding-block: 0.25rem !important;
    color: #000000 !important;

    &::after {
      display: none !important;
    }

    &:focus {
      box-shadow: none;
    }
    svg {
      transition: 0.2s;
      transform: rotate(45deg);
    }
  }

  .collapsed {
    svg {
      transform: rotate(0) !important;
    }
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none;
  }

  .accordion-body {
    padding-bottom: 0.5rem;
    padding-top: 0;
  }

  .accordion-collapse {
    a {
      text-decoration: none;
      margin-bottom: 0.25rem !important;
      transition: 0.2s;

      &:hover {
        color: white !important;
      }
    }
  }
  .accordion,
  .accordion-item,
  .accordion-button {
    background-color: transparent !important;
    border: none;
    cursor: pointer;
  }

  .accordion-item {
    background-color: #f5f5f7 !important;
    padding: 1rem;
    border: 1px solid #c9c9d1;
    border-radius: 12px;
    margin-bottom: 1rem;
  }
}
