@import "../base/vars";
@import "../base/helpers";

//Buttons

.btn-primary {
  @extend .font-nunito-regular;
  background-image: linear-gradient($color-green, $color-blue) !important;
  color: $color-white !important;
  border: none !important;
  border-radius: 30px !important;
  padding-left: 2em;
  padding-right: 2em;

  &:hover {
    background-image: linear-gradient($color-blue, $color-green) !important;
  }
}

.btn-outline-primary {
  @extend .font-nunito-regular;
  background-color: transparent !important;
  border: none !important;
  border: 1px solid $color-blue !important;
  border-radius: 30px !important;
  color: $color-blue !important;
  padding-left: 2em;
  padding-right: 2em;
  outline: none !important;

  &:hover {
    background-color: $color-blue !important;
    color: $color-white !important;
  }

  &:focus {
    box-shadow: unset !important;
  }
}

.btn-outline-grey {
  background-color: transparent !important;
  border: none !important;
  border: 1px solid #86868b !important;
  border-radius: 30px !important;
  color: #86868b !important;
  padding-left: 2em;
  padding-right: 2em;
  outline: none !important;

  &:hover {
    background-color: #86868b !important;
    color: $color-white !important;
  }

  &:focus {
    box-shadow: unset !important;
  }
}

// Special color fonts

.font-color-faded {
  color: $color-faded-lightgray !important;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-30 {
  font-size: 30px;
}

//font-size-14
@media screen and (max-width: 767px) {
  .font-size-14 {
    font-size: 12px;
  }

  .h1-custom-size {
    font-size: 2.2rem !important;
  }

  .h2-custom-size {
    font-size: 1.8rem !important;
  }

  .h4-custom-size {
    font-size: 1.6rem !important;
  }
}

//font-size-30
@media screen and (max-width: 991px) {
  .font-size-30 {
    font-size: 22px;
  }
}

@media screen and (max-width: 760px) {
  .font-size-30 {
    font-size: 18px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1440px) {
  .h1-custom-size {
    font-size: 2rem !important;
  }
}

@media screen and (max-width: 600px) {
  .h1-custom-size {
    font-size: 1.8rem !important;
  }

  .h2-custom-size {
    font-size: 1.5rem !important;
  }

  .h4-custom-size {
    font-size: 1.3rem !important;
  }
}

@media screen and (max-width: 400px) {
  .h1-custom-size {
    font-size: 1.5rem !important;
  }

  .h2-custom-size {
    font-size: 1.3rem !important;
  }

  .h4-custom-size {
    font-size: 1.2rem !important;
  }
}
