.content-container-col {
  margin-left: auto;
  margin-right: auto;
  width: 1400px;
  max-width: 100%;
  height: 100%;
}

.cryptodata-navbar-container {
  margin-left: auto;
  margin-right: auto;
  width: 1010px;
  max-width: 100%;
  height: 100%;
}

@media screen and (max-width: 1600px) {
  .content-container-col,
  .cryptodata-navbar-container {
    padding: 0 2rem !important;
  }
}

@media screen and (max-width: 550px) {
  .content-container-col,
  .cryptodata-navbar-container {
    padding: 0 1.2rem !important;
  }

  .article-container {
    padding: 1.2rem !important;
  }
}

.article-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  // max-width: 100%;
  height: 100%;
  padding: 0 15px;
}

@media screen and (max-width: 1100px) {
  .article-container {
    padding: 0 2rem;
  }
}
