#homepage-hero {
  min-height: 650px;
  background-image: url("./Assets/bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 991px) {
    min-height: 400px;
  }

  .p-container {
    max-width: 626px;
  }
}
