@import "./Style/components/index";
@import "./Style/components/containers";
@import "./Style/base/helpers";
@import "./Style/base/vars";

.white-color {
  color: white;
}

.gray-color {
  color: #888888;
}

.blue-color {
  color: #3092df;
}

.black-color {
  color: #000000;
}

.font-size-56 {
  font-size: 56px;

  @media screen and (max-width: 1400px) {
    font-size: 52px;
  }

  @media screen and (max-width: 1200px) {
    font-size: 48px;
  }

  @media screen and (max-width: 991px) {
    font-size: 38px;
  }

  @media screen and (max-width: 767px) {
    font-size: 32px;
  }

  @media screen and (max-width: 380px) {
    font-size: 28px;
  }
}
.font-size-24 {
  font-size: 24px;

  @media screen and (max-width: 1400px) {
    font-size: 22px;
  }

  @media screen and (max-width: 1200px) {
    font-size: 20px;
  }

  @media screen and (max-width: 767px) {
    font-size: 18px;
  }

  @media screen and (max-width: 580px) {
    font-size: 16px;
  }
}

.crypto-btn-link-base {
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  transition: 0.3s;

  &:hover {
    color: white;
  }
}
